<template>
    <div class="project-page-container">
        <div class="project-hero">
            <!-- <router-link to="/experience" class="project-router-link">return to projects</router-link> -->
            <h1 class="project-h1">InventoryLab&#39;s Product Alerts Feature</h1>
            <img class="project-hero-img il-hero-img" src="../assets/il-product-alerts-mockup.png" alt="InventoryLab Product Alerts">
            <p class="project-contribution">UX/UI Design</p>
            <p class="project-contribution">UI Development</p>
            <p class="project-contribution">REST API Integration</p>
        </div>
        <div class="project-background light-green">
            <div class="project-background-inner-container">
                <h2 class="project-background-h2">Background</h2>
                <p class="project-background-p">
                    InventoryLab is a SaaS product suite designed for ecommerce and Amazon sellers, consisting of the web app Stratify and its mobile companion, Scoutify. One key function is helping users find and list products on their Amazon Seller Central accounts. However, certain products may have restrictions imposed by Amazon, such as hazmat regulations or condition restrictions (new, used, etc). To address this, InventoryLab needed to provide users with clear visibility into these restrictions within its applications.
                </p>
            </div>
        </div>
        <div class="project-background">
            <div class="project-background-inner-container">
                <h2 class="project-background-h2">End Result & Business Impact</h2>
                <div class="business-impact">
                    <div class="business-impact-imgs">
                        <img class="scanzy-phone-mockup" src="../assets/il-phone-mockup.png" alt="Scanzy Website">
                        <img class="scanzy-web-mockup" src="../assets/il-product-alerts-mockup.png" alt="Scanzy Website">
                    </div>
                    <p>
                        By addressing user needs and collaborating with the product and development teams, I designed a feature called "Product Alerts." This feature includes icons and messages integrated into InventoryLab's web and mobile applications, informing users about Amazon's product restrictions.
                        <br><br>
                        Additionally, our team developed "Ideal Buy" notifications, enabling users to identify products they are most likely to succeed in selling based on customizable criteria in their settings.
                        <br><br>
                        As a result, users no longer had to guess which products were suitable for them to sell. Support tickets and complaints related to product alerts dropped to nearly zero. This led to a significant reduction in the time users spent sourcing products, enabling efficient listing of new items and directly boosting their revenue streams.                  </p>
                </div>
            </div>
        </div>
        <div class="project-background-inner-container">
            <h2 class="project-background-h2">Problem</h2>
            <p class="project-background-p">
                While speaking with users, our team discovered that many struggled with sourcing and listing new items on Amazon. They would identify a product in our catalog, navigate the lengthy listing process through our application, only to later find out from Amazon that the product had hazmat or condition restrictions. As a result, they either couldn't sell it or needed prior approval. If users had this information upfront, they would have avoided wasting time on unlistable products.            </p>
        </div>
        <div class="project-background-inner-container not-first-cream-background">
            <h2 class="project-background-h2">Defining Our Goals</h2>
            <p class="project-background-p">
                1. Display hazmat and condition restrictions for each product in our catalog.
                <br><br>
                2. Provide detailed messaging about these restrictions using information from Amazon.
                <br><br>
                3. Allow users to customize which restrictions they want to see based on their preferences.
            </p>
        </div>
        <div class="project-background-inner-container not-first-cream-background">
            <h2 class="project-background-h2">Creating Iconography</h2>
            <p class="project-background-p">
                One of the biggest challenges here was creating UI that was compact enough to fit into our existing workflows.  There were several areas in our applications where users could browse product catalogs and view search results. I saw this as an ideal spot to integrate the new product restriction alerts. I designed icons for Amazon condition restrictions and "hazmat" products, each with distinct visuals and colors to quickly grab attention and enhance the scanability of long search result lists.
                <br><br>
                I selected a blue color paired with an Amazon-related icon for the Amazon restrictions alert. Since blue was already commonly used throughout our application, it seamlessly integrated as a neutral, informational alert.
            </p>
            <img class="amzn-alert" src="../assets/amzn-alert.png" alt="Amazon Alert">
            <p>
                For the hazmat alerts, I chose an orange color to indicate a warning, emphasizing that these products could be hazardous and would require approval before selling.
                I intentionally avoided using red, as it felt too heavy-handed. Users weren’t completely barred from selling these items; they just needed approval.
            </p>
            <img class="hzmt-alert" src="../assets/hzmt-alert.png" alt="Hazmat Alert">
        </div>
        <div class="project-background-inner-container not-first-cream-background">
            <h2 class="project-background-h2">Providing Complete Messaging</h2>
            <p class="project-background-p">
                The icons made it easy for users to quickly scan product lists and identify restrictions. However, they didn’t provide enough detail about what the alerts meant. Space limitations on the product search results prevented adding more information, and repeating the same message for every item would have been redundant once users understood the alerts.
                <br><br>
                To solve this, I implemented modals for more in-depth details. Clicking the "View Product Alerts" link opened a modal with specific information.
                <br><br>
                For Amazon condition restrictions, the modal displayed the allowable conditions for selling the product and offered an option to request approval if needed. Each product had a single modal containing all relevant alerts, with expandable and collapsible sections for easy navigation of detailed information.
            </p>
            <img class="amzn-modal" src="../assets/amzn-modal.png" alt="Amazon Alert">
            <p>
                For Hazmat alerts, the modal provided general information applicable to all restricted items. Again, you could expand or collapse this information if need be.
            </p>
            <img class="hzmt-modal" src="../assets/hzmt-modal.png" alt="Hazmat Alert">
        </div>
        <div class="project-background-inner-container not-first-cream-background">
            <h2 class="project-background-h2">Gathering User Feedback &#38; Enhancing the Feature</h2>
            <p class="project-background-p">
                We presented our initial designs to users, and they overwhelmingly felt this solution would resolve their issue of not knowing which products were restricted. However, some users also expressed a desire for a feature to help them identify products ideal for their business—those ranking in the top tier of sellable items within a specific category.
                <br><br>
                Our team developed the "Ideal Buy" alert as a solution. I chose green for this alert because it universally represents success and opportunity, making it a natural fit for highlighting promising products.
            </p>
            <img class="hzmt-alert" src="../assets/ideal.png" alt="Ideal Buy">
            <p>
                Another thing users requested was the ability to customize their alerts, with some wanting to see only specific condition restrictions for certain items.
                <br><br>
                Additionally, since "Ideal Buy" meant different things to different users, we developed a settings area for personalizing their definition of "ideal."
                <br><br>
                I designed the settings section of our application to enable users to tailor their preferences, ensuring they only received alerts relevant to their needs.
            </p>
            <img class="settings-alerts" src="../assets/ideal-settings.png" alt="Hazmat Alert">
        </div>
        <div class="project-background-inner-container not-first-cream-background">
            <h2 class="project-background-h2">UI Development &#38; REST API Integration</h2>
            <p class="project-background-p">
                We had a solid design ready to solve users' urgent workflow issues. After finishing the UI, I handed it off for integration, but limited development resources threatened our timeline. To keep us on track, I learned to make API requests for alerts data from Amazon's API. This ensured a timely release, and users were thrilled with the new feature.
            </p>
        </div>
        <div class="project-background-inner-container not-first-cream-background">
            <h2 class="project-background-h2">Thanks for reading!</h2>
        </div>
        <div class="project-background-inner-container not-first-cream-background back-to-top-container">
            <a @click="scrollToTop">Back to top</a>
            <router-link to="/projects">More projects</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'InventoryLabPage',
    data: function() {
    return {

        };
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // This enables the smooth scroll effect
            });
        }
    }
}
</script>
