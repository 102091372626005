import { createRouter, createWebHistory } from 'vue-router';
import HomePage from './components/home.vue';

const routes = [
  { path: '/', component: HomePage },
  { path: '/skills', component: HomePage },
  { path: '/projects', component: HomePage },
  { path: '/scanzy', component: HomePage },
  { path: '/inventorylab', component: HomePage },
  { path: '/story', component: HomePage },
  { path: '/contact', component: HomePage },
  { path: '/404', component: HomePage },
  { path: '/:catchAll(.*)', component: HomePage }, // Wildcard route
];

const router = createRouter({
  history: createWebHistory(),
  routes
});

// Scroll to the top of the page before each navigation
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);
  next();
});

export default router;