<template>
  <div class="home" v-bind:class="{ smaller: navbarToggled }">
    <section class="home-hero" id="home-hero" v-bind:class="{ smaller: navbarToggled, scrolled: pageScrolled, 'small-width':(smallWidth && !navbarToggled), 'mobile-width':(mobileWidth && !navbarToggled) }">
      <div class="logo-parent-container" id="logo-parent-container" v-bind:class="{ moved: navbarToggled }">
        <div class="logo-container" v-bind:class="{ smaller: navbarToggled }" @click="toggleNavbar()">
          <img class="incomplete-logo" v-bind:class="{ smaller: navbarToggled }" src="../assets/incomplete-logo.svg" alt="jg-logo">
          <img class="bouncing-g" v-bind:class="{ smaller: navbarToggled }" src="../assets/bouncing-g.svg" alt="">
        </div>
      </div>

      <div class="name-container" v-bind:class="{ hidden: navbarToggled }">
        <div class="name">
          <h1><span class="name-h1">hi, i'm </span><span class="jack-gumina">jack gumina</span></h1>
          <p class="pronounce">(pronounced guh-MEE-nah)</p>
        </div>
        <h2 class="name-h2 big">i'm a designer <br>and front-end developer</h2>
        <h2 class="name-h2 name-h2-small">i'm a designer and <br>front-end developer</h2>
      </div>
    </section>
    <div class="home-hero-navigation" id="home-hero-navigation" v-bind:class="{ moved: navbarToggled, scrolled: pageScrolled, small: ((smallWidth || mobileWidth) && !navbarToggled) }" @click="handleNavigationClick($event)">
      <div class="nav-button" v-bind:class="{ smaller: navbarToggled, active: skillsVisible }" @click="toggleSkills()"><span>my skills</span></div>
      <div class="nav-button" v-bind:class="{ smaller: navbarToggled, active: experienceVisible || scanzyVisible || inventorylabVisible }" @click="toggleExperience()"><span>projects</span></div>
      <div class="nav-button" v-bind:class="{ smaller: navbarToggled, active: storyVisible }" @click="toggleStory()"><span>story</span></div>
      <div class="nav-button" v-bind:class="{ smaller: navbarToggled, active: contactVisible  }" @click="toggleContact()"><span>contact me</span></div>
    </div>
    <div class="hamburger" id="hamburger" @click="toggleHamburger" v-show="navbarToggled">
      <span class="bar" v-bind:class="{ turned: hamburgerToggled }"></span>
      <span class="bar" v-bind:class="{ turned: hamburgerToggled }"></span>
      <span class="bar" v-bind:class="{ turned: hamburgerToggled }"></span>
    </div>
  </div>
  <div class="hamburger-navigation" v-bind:class="{ visible: (hamburgerToggled && navbarToggled) }">
      <div v-bind:class="{ active: skillsVisible }" @click="toggleSkills(), toggleHamburger()"><span>my skills</span></div>
      <div v-bind:class="{ active: experienceVisible || scanzyVisible || inventorylabVisible }" @click="toggleExperience(), toggleHamburger()"><span>projects</span></div>
      <div v-bind:class="{ active: storyVisible }" @click="toggleStory(), toggleHamburger()"><span>story</span></div>
      <div v-bind:class="{ active: contactVisible }" @click="toggleContact(), toggleHamburger()"><span>contact me</span></div>
    </div>
  <Skills v-if="skillsVisible"/>
  <Experience @show-scanzy="toggleScanzy" @show-inventorylab="toggleInventorylab" v-if="experienceVisible"/>
  <Scanzy v-if="scanzyVisible"/>
  <Inventorylab v-if="inventorylabVisible"/>
  <Story v-if="storyVisible"/>
  <Contact v-if="contactVisible"/>
  <LostPage v-if="lostPageVisible"/>
  <button v-if="lostPageVisible" class="lost-button" @click="toggleNavbar()">go home</button>
  <img class="lost" src="../assets/lost.png" alt="Lost" v-if="lostPageVisible">
</template>

<script>
import Skills from './skills.vue'
import Experience from './experience.vue'
import Scanzy from './scanzy.vue'
import Inventorylab from './inventorylab.vue'
import Story from './story.vue'
import Contact from './contact.vue'
import LostPage from './404.vue'


export default {
  name: 'HomePage',
  components: {
    Skills,
    Experience,
    Scanzy,
    Inventorylab,
    Story,
    Contact,
    LostPage
  },
  data: function() {
    return {
      hamburgerToggled: false,
      navbarToggled: false,
      skillsVisible: false,
      storyVisible: false,
      experienceVisible: false,
      scanzyVisible: false,
      inventorylabVisible: false,
      contactVisible: false,
      lostPageVisible: false,
      pageScrolled: false,
      viewportWidth: window.innerWidth,
      isScrolling: false,
      smallWidth: false,
      mobileWidth: false
    }
  },
  watch: {
    // Watch for route changes and update component visibility accordingly
    $route(to) {
      this.updateComponentVisibility(to.path);
    }
  },
  created() {
    // Update component visibility based on initial route
    this.updateComponentVisibility(this.$route.path);

    document.title = 'Jack Gumina - Designer and Front-end Developer';

    if (this.$route.path === '/') {
      this.navbarToggled = false;
      document.title = 'Jack Gumina - Designer and Front-end Developer';
    } else {
      // Check if the current route is /skills
      if (this.$route.path === '/skills') {
        // Set skillsVisible to true
        this.skillsVisible = true;
        document.title = 'Skills';
      }
      // Check if the current route is /experience
      else if (this.$route.path === '/projects') {
        // Set experienceVisible to true
        this.experienceVisible = true;
        document.title = 'Projects';
      }
      // Check if the current route is /scanzy
      else if (this.$route.path === '/scanzy') {
        this.scanzyVisible = true;
        document.title = 'Scanzy';
      }
      // Check if the current route is /inventorylab
      else if (this.$route.path === '/inventorylab') {
        this.inventorylabVisible = true;
        document.title = 'InventoryLab Product Alerts';
      }
      // Check if the current route is /story
      else if (this.$route.path === '/story') {
        // Set storyVisible to true
        this.storyVisible = true;
        document.title = 'Story';
      }
      // Check if the current route is /contact
      else if (this.$route.path === '/contact') {
        // Set contactVisible to true
        this.contactVisible = true;
        document.title = 'Contact Me';
      }
      else if (!['/contact', '/story', '/projects', '/scanzy', '/inventorylab', '/skills', '/'].includes(this.$route.path)) {
        this.lostPageVisible = true;
        document.title = 'Page Not Found';
      }
      // Set navbarToggled to true if any of the routes are active
      this.navbarToggled = this.skillsVisible || this.experienceVisible || this.scanzyVisible || this.inventorylabVisible || this.storyVisible || this.contactVisible || this.lostPageVisible;
    }

    window.addEventListener('resize', this.handleResize); // Add resize event listener
    window.addEventListener('scroll', this.handleScroll);
  },
  mounted() {
    this.viewportWidth = window.innerWidth; // Get initial viewport width
    // Check if viewport width is below a certain point (e.g., 768px for mobile devices)
    if(this.viewportWidth <= 850 && this.viewportWidth > 520){
      this.smallWidth = true;
    } else {
      this.smallWidth = false;
    }
    if (this.viewportWidth <= 520){
      this.mobileWidth = true;
    } else {
      this.mobileWidth = false;
    }
  },
  methods: {
    // Method to update component visibility based on route
    updateComponentVisibility(routePath) {
      if (routePath === '/') {
        this.navbarToggled = false;
        this.skillsVisible = false;
        this.experienceVisible = false;
        this.scanzyVisible = false;
        this.inventorylabVisible = false;
        this.storyVisible = false;
        this.contactVisible = false;
        this.lostPageVisible = false;
      } else {
        this.navbarToggled = true;
        this.skillsVisible = routePath === '/skills';
        this.experienceVisible = routePath === '/projects';
        this.scanzyVisible = routePath === '/scanzy';
        this.inventorylabVisible = routePath === '/inventorylab';
        this.storyVisible = routePath === '/story';
        this.contactVisible = routePath === '/contact';
        this.lostPageVisible = !['/skills', '/projects', '/scanzy', '/inventorylab', '/story', '/contact'].includes(routePath);
      }
    },
    toggleHamburger() {
      if (this.hamburgerToggled == false){
        this.hamburgerToggled = true;
      } else {
        this.hamburgerToggled = false;
      }
    },
    toggleNavbar() {
      if (this.navbarToggled) {
        this.navbarToggled = false;
        this.skillsVisible = false;
        this.experienceVisible = false;
        this.scanzyVisible = false;
        this.inventorylabVisible = false;
        this.storyVisible = false;
        this.contactVisible = false;
        this.lostPageVisible = false;
        this.hamburgerToggled = false;
        this.$router.push('/');
        document.title = 'Jack Gumina - Designer and Front-end Developer';
        const navigation = document.getElementById('home-hero-navigation');
        const homeHero = document.getElementById('home-hero');
        const hamburger = document.getElementById('hamburger');
        navigation.style.position = 'static';
        homeHero.style.position = 'static';
        hamburger.style.position = 'static';
      }
    },
    toggleSkills() {
      if (this.skillsVisible == false) {
        this.skillsVisible = true;
        this.experienceVisible = false;
        this.scanzyVisible = false;
        this.inventorylabVisible = false;
        this.storyVisible = false;
        this.contactVisible = false;
        this.lostPageVisible = false;
        this.$router.push('/skills');
        document.title = 'Skills';
      }
      if (this.navbarToggled == false) {
        this.navbarToggled = true;
      }
    },
    toggleExperience() {
      if (this.experienceVisible == false) {
        this.experienceVisible = true;
        this.scanzyVisible = false;
        this.inventorylabVisible = false;
        this.skillsVisible = false;
        this.storyVisible = false;
        this.contactVisible = false;
        this.lostPageVisible = false;
        this.$router.push('/projects');
        document.title = 'Experience';
      }
      if (this.navbarToggled == false) {
        this.navbarToggled = true;
      }
    },
    toggleScanzy() {
      if (this.scanzyVisible == false) {
        this.experienceVisible = false;
        this.scanzyVisible = true;
        this.inventorylabVisible = false;
        this.skillsVisible = false;
        this.storyVisible = false;
        this.contactVisible = false;
        this.lostPageVisible = false;
        this.$router.push('/scanzy');
        document.title = 'Scanzy';
      }
      if (this.navbarToggled == false) {
        this.navbarToggled = true;
      }
    },
    toggleInventorylab() {
      if (this.inventorylabVisible == false) {
        this.experienceVisible = false;
        this.scanzyVisible = false;
        this.inventorylabVisible = true;
        this.skillsVisible = false;
        this.storyVisible = false;
        this.contactVisible = false;
        this.lostPageVisible = false;
        this.$router.push('/inventorylab');
        document.title = 'InventoryLab Product Alerts';
      }
      if (this.navbarToggled == false) {
        this.navbarToggled = true;
      }
    },
    toggleStory() {
      if (this.storyVisible == false) {
        this.storyVisible = true;
        this.skillsVisible = false;
        this.experienceVisible = false;
        this.scanzyVisible = false;
        this.inventorylabVisible = false;
        this.contactVisible = false;
        this.lostPageVisible = false;
        this.$router.push('/story');
        document.title = 'Story';
      }
      if (this.navbarToggled == false) {
        this.navbarToggled = true;
      }
    },
    toggleContact() {
      if (this.contactVisible == false) {
        this.contactVisible = true;
        this.storyVisible = false;
        this.skillsVisible = false;
        this.experienceVisible = false;
        this.scanzyVisible = false;
        this.inventorylabVisible = false;
        this.lostPageVisible = false;
        this.$router.push('/contact');
        document.title = 'Contact Me';
      }
      if (this.navbarToggled == false) {
        this.navbarToggled = true;
      }
    },
    handleResize() {
      // Update the viewport width on resize
      this.viewportWidth = window.innerWidth;
      // Adjust positions on resize
      this.setPositionOnScroll();
      this.smallWidth = this.viewportWidth <= 850 && this.viewportWidth > 520;
      this.mobileWidth = this.viewportWidth <= 520;
    },
    setPositionOnScroll() {
      // Check if scrolling is in progress
      if (!this.isScrolling) {
        // Select elements
        const logo = document.getElementById('logo-parent-container');
        const navigation = document.getElementById('home-hero-navigation');
        const homeHero = document.getElementById('home-hero');
        const hamburger = document.getElementById('hamburger');
        // Calculate the left position based on the reference element's position
        const leftPosition = logo.getBoundingClientRect().left - 1 + 'px';
        // Set positions based on scroll
        if (this.navbarToggled) {
          navigation.style.position = window.scrollY > 0 ? 'fixed' : 'static';
          homeHero.style.position = window.scrollY > 0 ? 'fixed' : 'static';
          hamburger.style.position = window.scrollY > 0 ? 'fixed' : 'static';
        }
        // If the position is fixed, set the left position
        if (navigation.style.position === 'fixed') {
          navigation.style.left = leftPosition;
          navigation.style.top = 77.5 + 'px';
          navigation.style.transition = '0s';
        }
        if (homeHero.style.position === 'fixed') {
          homeHero.style.transition = '0s';
          homeHero.style.width = '100%';
          homeHero.style.top = -55 + 'px';
          homeHero.style.boxShadow = '0 2px 4px 0 rgba(0,0,0,.2)';
        } else {
          homeHero.style.width = 'auto';
          homeHero.style.top = -55 + 'px';
          homeHero.style.boxShadow = 'none';
          homeHero.style.transition = '1s';
        }
        if (hamburger.style.position === 'fixed') {
          hamburger.style.top = 14 + 'px';
          hamburger.style.right = 0;
        }
      }
    },
    handleScroll() {
      // Set isScrolling flag to true to indicate scrolling
      this.isScrolling = true;
      // Debounce scroll event with setTimeout
      setTimeout(() => {
        // Set isScrolling flag to false after debounce timeout
        this.isScrolling = false;
        // Adjust positions on scroll
        this.setPositionOnScroll();
      }, 0);
    },
    handleNavigationClick(event) {
      // Calculate the click position relative to the left edge of the element
      const clickX = event.clientX - event.target.getBoundingClientRect().left;

      // Check if the click occurred within the leftmost 32 pixels of the element
      if (clickX <= 32 && window.getComputedStyle(event.target).position === 'fixed') {
        // If the condition is met, call the toggleNavbar method
        this.toggleNavbar();
      }
    }
  },
}
</script>
