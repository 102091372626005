<template>
    <div class="project-page-container">
        <div class="project-hero">
            <!-- <router-link to="/experience" class="project-router-link">return to projects</router-link> -->
            <h1 class="project-h1">Scanzy Barcode Scanning SDK</h1>
            <div class="scanzy-website-link-container"><a href="https://scanzy.com" target="_blank" class="button-link"><button class="scanzy-website-button">visit website</button></a></div>
            <img class="project-hero-img scanzy-hero-img" src="../assets/iphone-angle.png" alt="Scanzy">
            <p class="project-contribution">Branding &#38; logo design</p>
            <p class="project-contribution">Website design &#38; development</p>
            <p class="project-contribution">Mobile app design &#38; development</p>
        </div>
        <div class="project-background light-green">
            <div class="project-background-inner-container">
                <h2 class="project-background-h2">Background</h2>
                <p class="project-background-p">
                    Scanzy is a barcode-scanning software development kit (SDK) created by one of the talented engineers at InventoryLab.  It allows you to scan virtually any barcode, and translates the code into usable information for your application.  In the beginning, we had the installable SDK, but no way to go to market.  I was tasked with creating a logo, brand style guide, website, and mobile demo app, so we could launch Scanzy to the public.
                </p>
            </div>
        </div>
        <div class="project-background">
            <div class="project-background-inner-container">
                <h2 class="project-background-h2">End Result & Business Impact</h2>
                <div class="business-impact">
                    <div class="business-impact-imgs">
                        <img class="scanzy-phone-mockup" src="../assets/scanzy-mobile-app-mockup.png" alt="Scanzy Website">
                        <img class="scanzy-web-mockup" src="../assets/scanzy-web-mockup.png" alt="Scanzy Website">
                    </div>
                    <p>
                        I designed the logo for the Scanzy SDK and created a branding strategy that positioned us as a simple, low-cost option in the market. I collaborated with product and engineering teams to build a mobile demo app showcasing the SDK's barcode scanning capabilities while promoting our brand.
                        <br><br>
                        Additionally, I built a <a href="https://scanzy.com" target="_blank" class="scanzy-site-link">marketing website</a> from scratch where users could sign up for the SDK, track billing, manage their accounts, and follow integration instructions. This project helped grow Scanzy’s user base from zero to nearly 16,000 in the first year.
                    </p>
                </div>
            </div>
        </div>
        <div class="project-background-inner-container">
            <h2 class="project-background-h2">Problem</h2>
            <p class="project-background-p">
               We had a great barcode scanning tool that could scan at lightning speed and deliver results.  We knew we had a valuable tool, but we had no way to bring users on board.  We needed a way to sell our product.
            </p>
        </div>
        <div class="project-background-inner-container not-first-cream-background">
            <h2 class="project-background-h2">Defining Our Goals</h2>
            <p class="project-background-p">
                1. Design a logo and define a brand strategy that stands out from the competition.
                <br><br>
                2. Build a mobile demo app that showcases the SDK's ability to quickly and accurately scan different types of barcodes.  Include a settings portion of the app to manage barcode types and create a customized experience.
                <br><br>
                3. Create a marketing website that promotes our product while allowing users to sign up for a free trial, manage their account, view integration instructions, and access the demo app.
            </p>
        </div>
        <div class="project-background-inner-container not-first-cream-background">
            <h2 class="project-background-h2">Branding & Styles</h2>
            <p class="project-background-p">
                Our goal was to enter the market as a straightforward, low-cost barcode scanning SDK without unnecessary features. While competitors had a sleek, corporate look, we aimed to stand out with a retro, playful, and upbeat brand identity.
                <br><br>
                With this vision in mind, I began designing the logo, sketching out ideas and collaborating with my team to refine and narrow down our options.
                <br><br>
                We chose a logo that captured the retro, playful vibe we wanted while subtly incorporating elements that signaled its connection to barcodes.
            </p>
            <img class="project-img scanzy within-project-scanzy-logo" src="../assets/scanzy.png" alt="Scanzy">
            <p class="project-background-p">
                We also needed a color scheme that fit our retro, playful brand. I chose orange for its happy, energetic feel, balanced by blue-green as a complementary color. Cream added a unique, casual touch that set us apart from competitors.
                <br><br>
                All of the colors were selected to meet at least WCAG AA contrast guidelines.
            </p>
            <div class="scanzy-colors">
                    <img class="scanzy-color" src="../assets/color-scanzy-orange.png" alt="Burnt orange">
                    <img class="scanzy-color" src="../assets/color-scanzy-green.png" alt="Blue green">
                    <img class="scanzy-color blue-green-light" src="../assets/color-scanzy-green-light.png" alt="Blue green light version">
                    <img class="scanzy-color" src="../assets/color-scanzy-cream.png" alt="Cream">
            </div>
            <div class="scanzy-colors mobile-colors">
                    <div>
                        <img class="scanzy-color" src="../assets/color-scanzy-orange.png" alt="Burnt orange">
                        <img class="scanzy-color" src="../assets/color-scanzy-green.png" alt="Blue green">
                    </div>
                    <div>
                        <img class="scanzy-color blue-green-light" src="../assets/color-scanzy-green-light.png" alt="Blue green light version">
                        <img class="scanzy-color" src="../assets/color-scanzy-cream.png" alt="Cream">
                    </div>
            </div>
            <p class="project-background-p">
                We needed typography that was readable and matched our brand. For the header, I chose an all-caps version of "Official Book" for its retro look and clarity. For the secondary font, I selected "Poppins" for its legibility and simplicity.
            </p>
            <img class="scanzy-official" src="../assets/official.png" alt="Official Book">
            <img class="scanzy-official poppins" src="../assets/poppins.png" alt="Official Book">
            <p class="project-background-p">
                Buttons and links would be bold, yet simple.  I decided to use the uppercase Poppins font here because it's a bit more standard and practical for use in navigation.  I saved the use of Official Book for headers and punch lines.
            </p>
            <div class="scanzy-button-link">
                <img class="scanzy-button" src="../assets/scanzy-button.png" alt="Scanzy Button">
                <img class="scanzy-link" src="../assets/scanzy-link.png" alt="Scanzy Link">
            </div>
        </div>
        <div class="project-background-inner-container not-first-cream-background">
            <h2 class="project-background-h2">Building the Website</h2>
            <p class="project-background-p">
                For the Scanzy marketing website, I applied a thorough UX design process to create a site that would effectively communicate the value of the barcode scanning SDK while delivering a smooth and intuitive user experience. The goal was to showcase Scanzy’s capabilities, highlight its competitive pricing model, and guide users through the process of trying out the demo app and signing up for an account.
                <br><br>
                The <span class="bold">research phase</span> focused on understanding the needs of our target audience: software owners and developers looking for a reliable, cost-effective barcode scanning solution. I conducted competitive analysis to identify what other SDK providers were offering and pinpoint opportunities for Scanzy to stand out. From these insights, I defined the key features that needed to be highlighted, such as the ability to scan multiple barcode types quickly, robust integration guides, and developer support.
                <br><br>
                During the <span class="bold">ideation and wireframing</span> phase, I sketched out user flows and wireframes to map how visitors would interact with the website. The user flow needed to be straightforward—educating users on Scanzy's features, driving them to try out the demo app, and encouraging sign-ups for the free trial. Wireframes were created with a focus on clear calls to action, intuitive navigation, and an emphasis on the unlimited scans, fixed-price business model that differentiates Scanzy from its competitors.
            </p>
            <img class="scanzy-website-wireframe" src="../assets/scanzy-website-wireframe.png" alt="Scanzy Website Wireframe">
            <p class="project-background-p">
                In the <span class="bold">design phase</span>, I applied our brand style guide to the wireframes to create high-fidelity designs, ensuring a cohesive and user-friendly experience.
                I incorporated the Font Awesome icon library to add a playful touch and improve scanability.
            </p>
            <img class="scanzy-website-wireframe hi-fi" src="../assets/scanzy-website-hi-fi.png" alt="Scanzy Website Design">
            <p class="project-background-p">
                Once our team settled on a final design direction, we moved into the <span class="bold">development phase</span>. I chose to build the website with Vue.js, as it enables efficient data handling and seamless integration with our backend database managed by the developers. Additionally, Vue's component-based architecture allows for easier maintenance and scalability, making it an ideal choice for our project.            </p>
            <p class="project-background-p">
                In the <span class="bold">testing phase</span>, I evaluated the navigation and support guides by having one of our developers sign up for an account and integrate the SDK into a test app. He provided valuable recommendations to enhance site navigation, ensuring the process was intuitive and effective.
            </p>
        </div>
        <div class="project-background-inner-container not-first-cream-background">
            <h2 class="project-background-h2">Building the Mobile Demo App</h2>
            <img class="scanzy-app-mockup" src="../assets/phone-dark.png" alt="Scanzy Demo App">
            <p class="project-background-p">
                Building the mobile demo app followed a similar process to the website, encompassing research, wireframing, design, and development. However, the primary goal was to showcase the speed and accuracy of the barcode scanner.<br><br>
                We developed the app using the Ionic framework, leveraging its ability to compile into formats for both iOS and Android, which allowed us to reach a wider audience and provide a consistent experience across devices.
                <br><br>
                During testing, users noted that the app often detected other barcodes before they could scan their target and sometimes scanned too large an area. To address this, I designed a settings area that allowed users to:
                <br><br>
                1. Restrict the scan area.
                <br>
                2. Choose which barcodes to recognize.
                <br>
                3. Enable haptic feedback options, such as sounds and vibrations.
            </p>
            <img class="scanzy-app-mockup settings" src="../assets/scanzy-settings.png" alt="Scanzy Demo App Settings">
        </div>
        <div class="project-background-inner-container not-first-cream-background">
            <h2 class="project-background-h2">Thanks for reading!</h2>
        </div>
        <div class="project-background-inner-container not-first-cream-background back-to-top-container">
            <a @click="scrollToTop">Back to top</a>
            <router-link to="/projects">More projects</router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ScanzyPage',
    data: function() {
    return {

        };
    },
    methods: {
        scrollToTop() {
            window.scrollTo({
                top: 0,
                behavior: 'smooth' // This enables the smooth scroll effect
            });
        }
    }
}
</script>
