<template>
    <div class="contact-container">
        <h1>404 - Page Not Found</h1>
        <p class="contact-p">Oh no, it seems like you've wandered off the beaten path! Please check your url.</p>
    </div>
</template>

<script>

export default {
    name: 'LostPage',
    data: function() {
    return {

        };
    },
}
</script>