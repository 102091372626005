<template>
    <div class="story-container">
        <div class="story-pics-container">
            <img class="about-pic" src="../assets/about-pic-one.png" alt="">
            <img class="about-pic" src="../assets/about-pic-two.png" alt="">
            <img class="about-pic" src="../assets/about-pic-three.png" alt="">
        </div>
        <div class="story-words-container">
            <h1 class="story-h1">a little bit about me</h1>
            <p class="story-p">Interestingly enough my career journey started in finance. Fresh out of the University of Minnesota business school, I got my start in banking. It was a whirlwind introduction to professional life, and I learned many lessons I still apply to my career today.</p>
            <p class="story-p">After a while my inner creative rebelled. While I was grateful for the knowledge and relationships I had gained in the finance world, I craved more than spreadsheets and balance sheets. That's when I stumbled upon design. Through an apprenticeship, I dipped my toes into the design and front-end development waters, exploring what it takes to craft digital experiences for users. It was like unlocking a whole new world — one where I could blend my analytical skills with my artistic side and bring ideas to life in pixels and code.</p>
            <p class="story-p">Since then, I've been on a wild ride. Each job has been a crash course in learning. I've been able to engage my passion not just for aesthetics, but also the nuts and bolts of code. I've come to realize that marrying top-notch design with robust engineering isn't just desirable; it's essential. I'm here for every moment of that exciting journey.</p>
            <p class="story-p">When I'm not working, you can find me playing guitar, hiking with my Great Dane named Dave, or watching a classic TV series with my wife (Sopranos, anyone?).</p>
        </div>
    </div>
    <div class="jobs-container">
        <h1 class="experience-h1">my design journey: bridging creativity and code</h1>

        <div class="job-container">
            <div class="job-img-container">
                <img class="job-img threecolts" src="../assets/threecolts.png" alt="Threecolts">
            </div>
            <div class="job-description-container">
                <h2 class="job-h2"><a class="job-h2-name" href="https://www.threecolts.com" target="_blank">Threecolts</a><br>Sep 2023 - Feb 2024</h2>
                <p class="job-p">Following the acquisition of InventoryLab by Threecolts, my role evolved further into that of a front-end engineer. Combining my design expertise with my development skills, I worked on projects that required a holistic approach, from creating functioning prototypes to collaborating with cross-functional teams on a daily basis. Being able to leverage both my UX/UI and front-end development skill sets allowed me to tackle challenges creatively and contribute effectively to the success of various projects.</p>
            </div>
        </div>
        <div class="job-container">
            <div class="job-img-container">
                <img  class="job-img il" src="../assets/il-logo.png" alt="InventoryLab">
            </div>
            <div class="job-description-container">
                <h2 class="job-h2"><a class="job-h2-name" href="https://www.inventorylab.com" target="_blank">InventoryLab</a><br>Jan 2021 - Sep 2023</h2>
                <p class="job-p">During my time at InventoryLab, I wore multiple hats as a hybrid UX/UI designer and front-end developer. Collaborating closely with my team, I played a key role in designing and implementing innovative feature sets for our web and mobile SaaS products. I also developed two marketing websites from the ground up. I grew personally and professionally during my time at InventoryLab, and I'll always be grateful to the amazing folks there.</p>
            </div>
        </div>
        <div class="job-container">
            <div class="job-img-container">
                <img  class="job-img wrfw" src="../assets/wrfw.png" alt="Women Run For Women">
            </div>
            <div class="job-description-container">
                <h2 class="job-h2"><a class="job-h2-name" href="https://www.womenrunforwomen.com" target="_blank">Women Run For Women</a><br>Jun 2020 - Jan 2021</h2>
                <p class="job-p">At Women Run For Women, I had the opportunity to dive headfirst into the world of professional design, focusing on web and UX/UI design for a non-profit dedicated to women's health. It was a very rewarding experience that not only allowed me to apply my skills, but also taught me invaluable lessons along the way. Working on meaningful projects in a supportive environment was incredibly rewarding and laid the foundation for my design career.</p>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StoryPage',
    data: function() {
    return {

        };
    },
    methods: {

    }
}
</script>