<template>
    <div class="contact-container">
        <h1>hey there!</h1>
        <p class="contact-p">Simply fill out the form below and I'll get back to you as soon as I'm able.</p>
        <!-- modify this form HTML and place wherever you want your form -->
        <form
        action="https://formspree.io/f/xeqygoal"
        method="POST"
        class="contact-form"
        >
        <label class="form-label">
            <p class="contact-form-p">Your email:</p>
            <input class="contact-input email" type="email" name="email" v-model="formData.email">
        </label>
        <label class="form-label">
            <p class="contact-form-p">Your message:</p>
            <textarea class="contact-input message" name="message" v-model="formData.message"></textarea>
        </label>
        <!-- your other form fields go here -->
        <button class="contact-send-button" type="submit">send</button>
        </form>
    </div>
</template>

<script>

export default {
    name: 'ContactPage',
    data: function() {
    return {
        formData: {
                email: '',
                message: ''
            },
        };
    },
    methods: {

    },
    beforeRouteLeave(to, from, next) {
        // Before leaving the route, clear the form data
        this.formData.email = '';
        this.formData.message = '';
        next();
    }
}
</script>