<template>
    <div class="experience-container">
        <h1 class="experience-h1">some recent projects I've worked on...</h1>
        <div class="projects-container">
            <div class="project-div" @click="showScanzyLarge">
                <img class="project-img scanzy" src="../assets/scanzy.png" alt="Scanzy">
                <p class="project-p description">
                    A barcode scanning software development kit
                </p>
                <button class="project-button" @click="showScanzy">learn more</button>
                <div class="scanzy-mockups">
                    <img class="project-img scanzy-mockup" src="../assets/phone-angle.png" alt="Scanzy">
                    <img class="project-img scanzy-mockup" src="../assets/phone-dark.png" alt="Scanzy">
                    <img class="project-img scanzy-mockup" src="../assets/phone-glare.png" alt="Scanzy">
                </div>
                <p class="project-p ul-description">
                    I led:
                </p>
                <ul class="project-ul">
                    <li>Branding & graphic design</li>
                    <li>Website design & development</li>
                    <li>Mobile app design & frontend development</li>
                </ul>
            </div>
            <div class="project-div" @click="showInventorylabLarge">
                <img class="project-img il" src="../assets/il-logo-large.svg" alt="InventoryLab">
                <p class="project-p description il">
                    A "Product Alerts" feature for a suite of ecommerce-related SaaS applications
                </p>
                <button class="project-button" @click="showInventorylab">learn more</button>
                <div class="scanzy-mockups">
                    <img class="project-img il-mockup" src="../assets/il-product-alerts-mockup.png" alt="Scanzy">
                </div>
                <p class="project-p ul-description">
                    I led:
                </p>
                <ul class="project-ul">
                    <li>UX/UI design for mobile and web apps</li>
                    <li>Frontend development</li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExperiencePage',
    data: function() {
    return {

        };
    },
    methods: {
        showScanzy() {
            this.$emit('show-scanzy');
        },
        showInventorylab() {
            this.$emit('show-inventorylab');
        },
        showScanzyLarge() {
            if (window.innerWidth >= 651) {
                this.$emit('show-scanzy');
            }
        },
        showInventorylabLarge() {
            if (window.innerWidth >= 651) {
                this.$emit('show-inventorylab');
            }
        }
    }
}
</script>