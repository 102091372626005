<style lang="scss">
@import './assets/scss/styles.scss';
#app {
  font-family: Afacad, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
</style>

<template>
  <router-view>
  </router-view>
</template>

<script>
export default {
  name: 'App',
}
</script>
