<template>
    <div class="skills-container">
        <h1 class="skills-h1">here's a quick look at what I can do...</h1>
        <div class="skills-circle-container">
            <img src="../assets/dev-skills.png" alt="dev skills" class="skill-image dev">
            <img src="../assets/design-skills.png" alt="design skills" class="skill-image design">
            <img src="../assets/dev-skills-mobile.png" alt="dev skills" class="skill-image-mobile dev">
            <img src="../assets/design-skills-mobile.png" alt="design skills" class="skill-image-mobile design">
        </div>
    </div>
</template>

<script>
export default {
    name: 'SkillsPage',
    data: function() {
    return {

        };
    },
    methods: {

    }
}
</script>
